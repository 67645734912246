import { AgentsState } from './agents/agents.state'
import AgentsAssistanceState from './agentsAssistance/agentsAssistance.state'
import AnnouncementsState from './announcement/announcement.state'
import AppState from './app/app.state'
import AuthState from './auth/auth.state'
import CallState from './call/call.state'
import CallLogState from './callLog/callLog.state'
import CallV2State from './callV2/callV2.state'
import CampaignManagementState from './campaignManagement/campaignManagement.state'
import CaseState from './case/case.state'
import IChannelSettingsState from './channelSettings/state'
import { ChatState } from './chat/chat.state'
import ContactState from './contact/contact.state'
import IContactHistoryState from './contactHistory/contactHistory.state'
import IContactSearchState from './contactSearch/contactSearch.state'
import ICustomMessagingState from './customMessaging/customMessaging.state'
import { DataRetentionState } from './dataRetetion/dataRetention.state'
import { IDirectoryState } from './directory/directory.state'
import { ErrorsState } from './errors/errorsSlice'
import GlobalState from './global/global.state'
import KendraPluginState from './kendraPlugin/kendraPlugin.state'
import MetricsState from './metrics/metrics.state'
import NotificationState from './notification/notification.state'
import { QAState } from './qa/qa.state'
import QueuedTasksState from './queuedTasks/queuedTasks.state'
import { ScreenRecordingState } from './screenRecording/screenRecording.state'
import SettingsState from './settings/settings.state'
import { TasksState } from './tasks/tasks.state'
import UserState from './user/user.state'
import { VideoMeetingState } from './videoMeeting/videoMeeting.state'
import WebHIDState from './webHID/webHID.state'
import WebsocketState from './websocket/websocket.state'
import WebsocketCompanionState from './websocketCompanion/websocketCompanion.state'

export default interface RootState {
    agents: AgentsState
    agentsAssistance: AgentsAssistanceState
    announcements: AnnouncementsState
    app: AppState
    auth: AuthState
    call: CallState | null
    callLog: CallLogState
    callV2: CallV2State
    campaignManagement: CampaignManagementState
    case: CaseState
    channelSettings: IChannelSettingsState
    chat: ChatState
    contact: ContactState | null
    contactHistory: IContactHistoryState[]
    contacts: ContactState[] | []
    contactSearch: IContactSearchState
    customMessaging: ICustomMessagingState
    directory: IDirectoryState
    global: GlobalState
    kendraPlugin: KendraPluginState
    metrics: MetricsState
    notification: NotificationState
    qa: QAState
    queuedTasks: QueuedTasksState
    settings: Partial<SettingsState>
    tasks: TasksState
    user: UserState | null
    webHIDState: WebHIDState
    websocket: WebsocketState | null
    websocketCompanion: WebsocketCompanionState
    errors: ErrorsState
    dataRetention: DataRetentionState
    videoMeeting: VideoMeetingState
    screenRecording: ScreenRecordingState
}

export enum LoadingStates {
    LOADING,
    LOADED,
    ERROR,
    NOT_FOUND,
}
